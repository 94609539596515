@import 'styles/variables.scss';

.notFound-page {
	padding: 20px 30px;
	margin-bottom: 2.6rem;
    text-align: center;

	@media screen and (min-width: $tablet) {
		padding: 30px 60px;
	}

	@media screen and (min-width: $desktop) {
		max-width: $desktop;
		padding: 30px 80px;
		margin: auto;
	}

	h1{
        color: initial;
    }

    img{
        margin-top: 20px;
        width: 100%;
    }
}
.notFound-page+.footer .footer-header, .notFound-page+.footer .ant-spin-nested-loading{
    display: none !important;
}
.notFound-page+.footer .footer-content{
    justify-content: center !important;
}
