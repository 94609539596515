@import 'styles/variables.scss';

.previous-kittens-page {
	.kittens-list {
		padding: 2rem;
		margin: auto;

		@media screen and (min-width: $tablet) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-row-gap: 4rem;
		}

		@media screen and (min-width: $desktop) {
			padding: 4rem;
		}

		@media screen and (min-width: $l-desktop) {
			grid-template-columns: repeat(3, 1fr);
			padding: 6rem;
		}

		.kitten {
			margin: 0 auto 2.6rem auto;
			transition: transform ease-in-out 200ms;
			text-align: center;

			.kitten-img {
				cursor: pointer;
				&:hover {
					transform: scale(1.01);
				}
			}

			h3 {
				color: $fujipaws-pink;
				margin-bottom: 0.4rem;
				@media screen and (min-width: $tablet) {
					margin-bottom: 0.6rem;
				}
			}

			.preview-link {
				cursor: pointer;
				color: $grey;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
