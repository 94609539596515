.photos-grid-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 40px;

	button {
		margin: auto;
		white-space: nowrap;
		width: fit-content;
	}
}

.carols-container {
	margin-top: 2rem;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
	width: 100%;

	> img {
		width: 100%;
		height: 100%; 
		object-fit: cover;
	}
}