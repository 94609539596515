@import 'styles/variables.scss';

.footer-menu {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 60px;

	@media only screen and (min-width: $tablet) {
		width: 50%;
		margin-top: 0px;
		margin-left: 50px;
		flex-wrap: unset;
	}

	@media only screen and (min-width: $desktop) {
	}

	.contents-category {
		display: flex;
		flex-direction: column;
		width: 50%;

		h3 {
			text-transform: uppercase;
			margin: 0 0 30px 0;
			color: $grey;
		}

		@media (max-width: $xs-tablet) {
			width: 100%;
		}



		.content {
			display: flex;
			flex-direction: column;

			a {
				margin-bottom: 20px;
				font-size: 14px;
				margin-bottom: 15px;
				color: $grey;

				&:hover {
					color: $hover-pink;
				}

				@media only screen and (min-width: $tablet) {
					font-size: 16px;
				}

				@media only screen and (min-width: $desktop) {
					font-size: 18px;
				}
				a:hover {
					color: $hover-pink;
				}
			}
		}
	}
}
