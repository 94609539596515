@import 'styles/variables.scss';

.popup-wrapper {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba($color: #000, $alpha: 0.1);
	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;

	.popup-container {
		margin: auto;
		background-color: $white;
		align-items: center;
		text-align: center;
		padding: 3.5rem 3.5rem 5rem 3.5rem;
		border-radius: 8px;
		height: 100vh;

		@media only screen and (min-width: $tablet) {
			height: initial;
			position: relative;
		}

		p {
			position: absolute;
			top: 1.2rem;
			right: 1.2rem;
			font-size: 16px;
			cursor: pointer;

			@media only screen and (min-width: $tablet) {
			}
		}

		.barcode {
			background-color: #f4f4f4;
			padding: 5px;
			margin: 20px 45px;

			img {
				width: 300px;
				height: auto;
			}
		}

		span {
			margin-bottom: 1.4rem;
		}

		h3 {
			color: $grey;
		}

		a {
			color: $grey;
			font-size: 16px;
			margin: 10px 0;
		}
	}
}
