.fade-in-section {
    opacity: 0.2;
    transform: translate(0, 30%);
    visibility: hidden;
    transition: opacity 400ms ease-in-out, transform 400ms ease-in-out;
    will-change: opacity, visibility;
}
  
.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
    display: flex; 
}