@import 'styles/variables.scss';

.about-us-page {
	position: relative;
	overflow: hidden;
	
	@media screen and (min-width: $desktop) {
		margin-top: 4rem;
	}

	&.eng-version {
		padding: 1rem;
		text-align: center;
		font-size: 1rem;
    	line-height: 2rem;

		.inner-wrapper {
			width: 100%;

			@media screen and (min-width: $tablet) {
				width: 800px;

			}
			.content {
				text-align: left;
			}
		}

		.image-containers {
			display: flex;
			flex-wrap: wrap;
			align-content: center;

			> img {
				object-fit: cover;
			}
		}

		@media screen and (min-width: $desktop) {
			// padding: auto 12rem;
			display: flex;
			align-items: center;

			div {
				max-width: 800px;
				width: 800px;
			}
		}

		div {
			margin: 2rem auto;
		}

		img {
			max-width: 300px;
			display: block;
			margin: 1rem auto;
			border-radius: 10px;
		}
	}

	.background-image {
		position: absolute;
		z-index: -999;
		overflow: hidden;

		&.background-image-left {
			width: 13rem;
			left: -5rem;
			top: 12rem;

			@media screen and (min-width: $tablet) {
				width: 18rem;
				left: -6rem;
				top: 18rem;
			}

			@media screen and (min-width: $desktop) {
				width: 18rem;
				top: 22rem;
			}
		}

		&.background-image-right {
			width: 8rem;
			right: -3rem;
			top: 5rem;

			@media screen and (min-width: $desktop) {
				width: 8rem;
				right: -3rem;
				top: 5rem;
			}

			@media screen and (min-width: $desktop) {
				width: 10rem;
			}
		}

		&.background-image-bottom {
			width: 10rem;
			right: -3rem;
			bottom: 0rem;

			@media screen and (min-width: $desktop) {
				width: 10rem;
				right: -3rem;
			}

			@media screen and (min-width: $desktop) {
				width: 12rem;
			}
		}
	}

	.summary-content {
		margin: 2rem 3rem;
		max-width: 1000px;

		@media screen and (min-width: $desktop) {
			margin: 2rem auto;
		}

		> * {
			margin-bottom: 1rem;
		}

		.signature {
			margin-top: 2rem;
			margin-bottom: 4rem;
			margin-right: 1rem;
			text-align: right;
			font-weight: bold;
		}
	}
}
