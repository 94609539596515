@import 'styles/variables.scss';

.kitty-detail-container {
	padding-bottom: 80px;

	h2{
		color: #bc8a77;
		text-align: center;
	}

	h1 {
		@media screen and (min-width: $desktop) {
			padding-bottom: 50px;
		}
	}

	.img-carousel-block {
		padding: 0;
		max-width: 1280px;
		margin: auto;

		.sc-bxivhb {
			display: flex;
			justify-content: center;
		}

		.cat-img {
			border-radius: 10px;
			object-fit: contain;
			margin: auto;
			max-width: 880px;
			max-height: 540px;
			width: 80vw;
			height: 50vw;
		}
	}

	h3 {
		text-align: center;
	}
	
	.cat-info-container {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;

		@media screen and (max-width: $s-tablet) {
			justify-content: space-between;
		}

		.cat-info-block {
			margin-right: 20px;
			margin-bottom: 20px;
			width: 120px;
			height: 120px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border-radius: 4px;
			background-color: #faf5f4;
			box-shadow: 2px 2px 5px rgba($color: transparent, $alpha: 0.16);

			svg {
				width: 40px;
				height: 40px;
			}

			@media screen and (max-width: $s-tablet) {
				width: 60px;
				height: 60px;
				margin: 0;

				svg {
					width: 30px;
					height: 30px;
				}

				p {
					font-size: 10px;
				}
			}
		}
	}

	.kitty-detail-button {
		display: flex;
		justify-self: center;
		margin: 50px;
		button {
			width: fit-content;
			margin: auto;
			padding: 10px 50px;
		}
	}

	.kitty-detail-parents-container {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;

		@media screen and (max-width: $tablet) {
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

		.kitty-detail-parents-block {
			margin-right: 50px;

			h2 {
				width: 100%;
			}
			img {
				width: 300px;
				height: 300px;
				object-fit: cover;
				border-radius: 4px;
			}

			@media screen and (max-width: $tablet) {
				margin-right: 0;

				h2 {
					text-align: center;
				}
			}
		}
	}

	h2,
	.cat-info-container,
	.kitty-detail-parents-container {
		width: 80vw;
		margin: auto;
		max-width: 880px;
		padding: 20px 0;
	}
}
