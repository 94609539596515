@import 'styles/variables.scss';

.banner {
	background-color: $background-pink;
	width: 100%;
	padding: 60px;
	position: relative;
	overflow: hidden;
	text-align: center;

	.banner-content {
		width: 100%;
		height: 100%;
		margin: auto;

		img {
			width: 5rem;
			height: auto;
		}
		.search-bar {
			max-width: 360px;
			margin: auto;

			.ant-input-affix-wrapper {
				border-radius: 30px;
				border: none;
				margin-bottom: 30px;

				.ant-input-prefix {
					margin-right: 8px;
					color: #ccc;
				}
			}

			.ant-input-affix-wrapper-lg {
				padding: 8px 16px;
				box-shadow: 10px 10px 18px rgba($color: #898989, $alpha: 0.16);
			}
		}
	}

	.icon {
		position: absolute;
	}

	.thunder {
		width: 6rem;
		top: 30px;
		left: -22px;
	}

	.star {
		width: 7rem;
		left: -18px;
		bottom: -20px;
	}

	.dot {
		width: 6em;
		right: -30px;
		top: 35%;
	}
}

@media only screen and (min-width: $tablet) {
	.banner {
		min-height: 300px;

		.banner-content {
			img {
				width: 6rem;
			}

			.search-bar {
				max-width: 400px;

				.ant-input-affix-wrapper {
					border-radius: 40px;
					margin-bottom: 40px;

					.ant-input-prefix {
						margin-right: 10px;
					}
				}

				.ant-input-affix-wrapper-lg {
					padding: 14px 22px;
					box-shadow: 12px 12px 24px rgba($color: #898989, $alpha: 0.16);
				}
			}
		}

		.thunder {
			width: 10rem;
			top: 40px;
			left: -22px;
		}

		.star {
			width: 14rem;
			left: -28px;
			bottom: -60px;
		}

		.dot {
			width: 10em;
			right: -30px;
			top: 35%;
		}
	}
}

@media only screen and (min-width: $desktop) {
	.banner {
		min-height: 450px;

		.banner-content {
			h2 {
				margin-top: 40px;
			}
			img {
				width: 6rem;
			}

			.search-bar {
				max-width: 530px;

				.ant-input-affix-wrapper {
					margin-bottom: 40px;
				}
			}
		}
	}
}
