.photos-grid-container {
	padding-bottom: 60px;
	.photos-grid-front {
		z-index: 1;
	}
	.photos-grid-back {
		z-index: -1;
		opacity: 0;
		position: fixed;
		transition: all 0.4s ease-out;

		&.active {
			position: static;
			opacity: 1;
			transition: all 0.4s ease-in;
		}
	}

	.photos-grid-block {
		transform: translateY(-20%);
		transition: all 0.5s ease-in;

		&.active {
			transform: translateY(0);
			transition: all 0.5s ease-out;
		}
	}

	.photos-grid-button {
		padding-top: 50px;
		display: flex;
		justify-content: center;

		button {
			background-color: transparent;
			box-shadow: 2px 2px 10px rgba($color: transparent, $alpha: 0.16);
		}
	}
}

.gallery-images-container {
	margin-top: 4rem;

	.gallery-images {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	
		> img {
			width: 100%;
			height: 100%; 
			object-fit: contain;
		}
	}
	
}