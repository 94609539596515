@import 'styles/variables.scss';

.kitten-preview-wrapper {
	background-color: #f9f3f3;
	border-radius: 15px;
	box-shadow: 5px 10px 12px rgba($color: $background-white, $alpha: 1);
	width: 100%;
	margin: 1rem;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 2rem;
	transition: transform ease-in-out 200ms;

	&:hover {
		transform: scale(1.01);
		background-color: darken($color: $background-white, $amount: 2);
	}

	@media screen and (min-width: $desktop) {
		width: calc(50% - 2rem);
	}

	.image-wrapper {
		@media screen and (min-width: $tablet) {
			width: 45%;
		}

		img {
			width: 100%;
		}
	}

	.info-wrapper {
		@media screen and (min-width: $tablet) {
			width: 45%;
		}

		.detail-infos {
			span {
				margin-right: 20%;
				white-space: nowrap;
			}
		}

		h3 {
			font-weight: bold;
			font-size: 1.1rem;
		}

		.description {
			margin-top: 1rem;
		}

		.preview-link {
			background-color: $fujipaws-pink;
			color: white;
			margin-top: 2rem;
			font-size: 1.1rem;
			border-radius: none;
			border: none;
			
			border-radius: 15px;
    		padding: 0.2rem 0.5rem;

			&:hover {
				cursor: pointer;
				background-color: white;
				color: $fujipaws-pink;
			}
		}
	}
}
