@import 'styles/variables.scss';

.fujipaw-btn {
	min-width: 9rem;
	padding: 0.5rem 1rem;
	border-radius: 2px;
	cursor: pointer;
	outline: none;
	transition: transform ease-in-out 300ms;
	font-weight: 600;
	border: 2px solid #454545;
	color: #3b3b3b;
	background-color: $btn-background-pink;
	box-shadow: 12px 12px 24px rgba($color: #be6f4b, $alpha: 0.16);

	&:hover {
		transform: scale(1.03);
		background-color: lighten($color: #e2c2b8, $amount: 5);
		border-color: lighten($color: #454545, $amount: 5);
	}
}

.btn-white {
	color: #3b3b3b;
	background-color: $white;
	box-shadow: 12px 12px 24px rgba($color: #acacac, $alpha: 0.16);

	&:hover {
		transform: scale(1.03);
		background-color: lighten($color: $white, $amount: 5);
		border-color: lighten($color: #454545, $amount: 5);
	}
}
