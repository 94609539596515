@import 'styles/variables.scss';

.mobile-nav {
	padding: 0.4rem 1rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 0;
	z-index: 999;
	width: 100%;
	transition: all 0.4s ease-in-out;

	@media only screen and (min-width: $tablet) {
		padding: 1rem 2.2rem;
	}

	img {
		width: 30px;
	}

	button {
		background-color: transparent;
		outline: none;
		font-weight: 700;
		border: none;
		color: $fujipaws-pink;
	}
}

.ant-drawer-body {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.ant-menu-inline {
		border-right: none;
	}

	.ant-menu-item-active {
		a {
			color: $hover-pink;
		}
	}
}
