// screen size
$xs-tablet: 380px;
$s-tablet: 690px;
$tablet: 768px;
$desktop: 1024px;
$l-desktop: 1400px;

// color
$white: #ffffff;
$background-white: #f4f4f4;

$hover-pink: #cea697;
$fujipaws-pink: #bc8a77;
$background-pink: #faf5f4;
$btn-background-pink: #e2c2b8;

$grey: #575757;

$text-black: #1f1f1f;
