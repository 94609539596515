@import 'styles/variables.scss';

.show-result-item-wrapper {
	width: 100%;
	margin: 0 auto 80px auto;
	text-align: center;

	.cat-img {
		width: 90%;
		transition: transform ease-in-out 400ms;
		height: 400px;
	}
	@media screen and (min-width: $tablet) {
		.cat-img {
			width: 60%;
			transition: transform ease-in-out 400ms;
			height: 600px;
		}
	}

	h3 {
		text-transform: uppercase;
	}
}
