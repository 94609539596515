@import 'styles/variables.scss';

.card-container {
	background-color: $white;
	border-radius: 10%;
	padding: 15px;

	.card-img {
		border-radius: 5px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		margin: auto;
		width: 22vw;
		height: 15vw;
		max-width: 346px;
		max-height: 216px;

		@media screen and (max-width: $s-tablet) {
			width: 54vw;
			height: 40vw;
			max-width: 100vw;
			max-height: 100vw;
		}
	}

	.card-words {
		width: 22vw;
		margin: auto;
		max-width: 346px;

		@media screen and (max-width: $s-tablet) {
			width: 54vw;
			max-width: 100vw;
		}

		p {
			margin-bottom: 10px;
		}

		h4 {
			text-align: right;
			margin-bottom: 10px;
			font-weight: 400;
		}

		img {
			margin: 10px 0 8px 0;
			width: 10vw;
			max-width: 120px;
			height: auto;
		}
	}
}
