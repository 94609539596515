@import 'styles/variables.scss';

.cat-detail-wrapper {
	margin: auto;

	text-align: center;
	position: relative;
	overflow: hidden;

	.cat-detail-banner {
		@media screen and (min-width: $tablet) {
			padding: 2rem;
		}

		@media screen and (min-width: $desktop) {
			padding: 4rem;
		}

		img {
			width: 100%;
		}

		span {
			font-weight: 600;
		}

		h3 {
			text-transform: uppercase;
		}
		.cat-main-photo {
			width: calc(100% - 8rem);

			@media screen and (min-width: $desktop) {
				width: calc(100% - 20rem);
			}
		}

		.cat-name {
			margin-bottom: 2rem;
		}
	}

	.cat-images {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 10px;
	}

	.background-image {
		position: absolute;
		z-index: -999;
		overflow: hidden;

		&.background-image-left {
			width: 13rem;
			left: -5rem;
			top: 12rem;

			@media screen and (min-width: $tablet) {
				width: 18rem;
				left: -6rem;
				top: 18rem;
			}

			@media screen and (min-width: $desktop) {
				top: 22rem;
			}
		}

		&.background-image-right {
			width: 8rem;
			right: -3rem;
			top: 5rem;

			@media screen and (min-width: $desktop) {
				width: 10rem;
			}
		}
	}
}
