@import 'styles/variables.scss';

.deposit-page {
	padding: 20px 30px;
	margin-bottom: 2.6rem;

	@media screen and (min-width: $tablet) {
		padding: 30px 60px;
	}

	@media screen and (min-width: $desktop) {
		max-width: $desktop;
		padding: 30px 80px;
		margin: auto;
	}

	.typography-paragraph {
		text-indent: 40px;
	}

	.deposit-questions {
		margin-bottom: 2rem;
	}

	.deposit-heading {
		width: 100%;
		text-align: center;
		margin-bottom: 40px;

		@media screen and (min-width: $tablet) {
			margin-bottom: 3.8rem;

			h2 {
				margin-bottom: 12px;
			}
		}

		@media screen and (min-width: $desktop) {
			margin-bottom: 4.6rem;

			h2 {
				margin-bottom: 18px;
			}
		}
	}

	.deposit-important-msg {
		padding: 6px 12px;
		margin: 30px 0;

		p {
			font-weight: 700;
		}
	}

	.deposit-options-container {
		text-align: center;

		@media screen and (min-width: $tablet) {
			margin-top: 2.4rem;

			.payment-options {
				display: flex;
				justify-content: center;

				.payments {
					display: flex;
				}
			}
		}

		h4 {
			color: $fujipaws-pink;
		}

		button {
			margin-bottom: 16px;

			@media screen and (min-width: $tablet) {
				margin-right: 2.4rem;

				&:first-of-type {
					margin-left: 2.4rem;
				}
			}

			@media screen and (min-width: $desktop) {
				margin-right: 2.8rem;
			}
		}
	}
}
