@import 'styles/variables.scss';

.desktop-nav {
	padding: 0.5rem 2rem;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: fixed;
	top: 0;
	z-index: 999;
	width: 100%;
	transition: all 0.4s ease-in-out;

	.navbar-logo {
		width: 30px;
		margin-right: 1rem;

		@media only screen and (min-width: $l-desktop) {
			margin-right: 1.8rem;
		}
	}

	.ant-menu-horizontal {
		border-bottom: none;
		color: $text-black;
		background-color: transparent;

		.ant-menu-item {
			a {
				color: $text-black;

				&:hover {
					color: $hover-pink;
				}
			}

			&.ant-menu-item-selected {
				a {
					color: $hover-pink;
				}
			}
		}
	}

	@media only screen and (min-width: $desktop) {
		padding: 1.2rem 2.7rem;
	}

	@media only screen and (min-width: $l-desktop) {
		padding: 1.3rem 3.7rem;
	}
}
