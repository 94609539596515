@import 'styles/variables.scss';

.lang-switch {
    font-size: 0.7rem;
    color: #194543;

    span {
        &:hover {
            color: $hover-pink;
            cursor: pointer;
        }
    }
}