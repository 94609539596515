@import 'styles/variables.scss';

.available-kittens-page {
	.kittens-list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;

		@media screen and (min-width: $tablet) {
			padding: 2rem;
		}

		@media screen and (min-width: $desktop) {
			padding: 4rem;
		}

		@media screen and (min-width: $l-desktop) {
			padding: 6rem;
		}
	}

	.no-available-kitten {
		margin: 6rem auto;
		font-size: 1.2rem;
		text-align: center;

		@media screen and (min-width: $tablet) {
			margin: 6rem 10rem;
		}
	}
}
