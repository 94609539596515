@import 'styles/variables.scss';

.faq-page {
	width: 100%;
	margin-top: 30px;

	.faq-more-question {
		text-align: center;
		padding: 60px 0;

		img {
			width: 40px;
			margin-bottom: 20px;

			@media only screen and (min-width: $tablet) {
				width: 50px;
			}

			@media only screen and (min-width: $desktop) {
				width: 60px;
			}
		}

		p {
			font-weight: 700;
			margin-bottom: 20px;
		}

		button {
			font-weight: 600;
		}
	}
}
