@import 'styles/variables.scss';

.faq-item-container {
	.faq-item {
		margin-bottom: 16px;
		background-color: $background-white;
		padding: 6px 12px;
		border-radius: 8px;
		overflow-y: hidden;

		@media only screen and (min-width: $tablet) {
			padding: 10px 16px;
		}

		@media only screen and (min-width: $desktop) {
			padding: 12px 18px;
		}

		.faq-question {
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;

			p {
				font-weight: 600;
				color: $grey;
			}

			button {
				cursor: pointer;
				border: none;
				outline: none;
				background-color: transparent;
			}
		}

		.faq-answer {
			max-height: 0;
			opacity: 0;
			transition: all 0.25s ease-in;
		}

		.showMore {
			max-height: 400px;
			opacity: 1;
			transition: all 1s ease-out;
		}
	}
}
