@import 'styles/variables.scss';

.bring-kitten-home-container {
	margin: 20px auto;
	padding: 20px 40px;
	width: 100%;
	max-width: 700px;
	text-align: center;

	.kitten-image {
		background-color: #f0e4d8;
		border-radius: 10px;
		width: 215px;
		height: 220px;
		transform: rotate(-8deg);
		margin: 0 auto 45px auto;

		.kitten {
			width: 215px;
			transform: rotate(8deg);
			position: relative;
			left: 12px;
			border-radius: 10px;
		}

		.dot {
			width: 55px;
			position: relative;
			right: 6.8rem;
			top: -2rem;
		}
	}

	.kitten-content {
		margin-bottom: 20px;

		h3 {
			text-transform: capitalize;
			letter-spacing: 0.5px;
			color: $grey;
		}

		p {
			margin-bottom: 40px;
			line-height: 24px;
		}

		button {
			font-weight: 600;
		}

		&:hover ~ .kitten-image {
			transform: rotate(-5deg);
		}
	}
}

@media only screen and (min-width: $tablet) {
	.bring-kitten-home-container {
		margin: 80px auto;
		width: 100%;
		max-width: 780px;
		display: flex;
		justify-content: space-between;
		flex-direction: row-reverse;

		.kitten-image {
			width: 245px;
			height: 255px;
			transform: rotate(-8deg);

			.kitten {
				width: 250px;
			}

			.dot {
				width: 65px;
				right: 7.7rem;
				top: -1.8rem;
			}
		}

		.kitten-content {
			width: 50%;
			margin-bottom: 20px;

			h3 {
				text-transform: capitalize;
				letter-spacing: 0.5px;
			}

			p {
				margin-bottom: 40px;
				line-height: 32px;
			}

			button {
				font-weight: 600;
			}
		}
	}
}

@media only screen and (min-width: $desktop) {
	.bring-kitten-home-container {
		margin: 100px auto;
		max-width: $desktop;
		display: flex;
		justify-content: space-between;

		.kitten-image {
			width: 245px;
			height: 255px;

			.kitten {
				width: 250px;
			}

			.dot {
				width: 65px;
				right: 7.8rem;
				top: -34px;
			}
		}

		.kitten-content {
			width: 50%;

			p {
				line-height: 32px;
			}
		}
	}
}
