@import 'styles/variables.scss';

.home-banner-container {
	.banner-img {
		object-fit: cover;
		width: 100vw;
		height: 60vh;

		@media only screen and (min-width: $s-tablet) {
			height: 70vw;
		}
	}

	.home-banner-block {
		.banner-words-block {
			position: absolute;
			top: 40%;
			left: 10%;

			@media only screen and (max-width: $s-tablet) {
				top: 28%;
			}
			@media only screen and (min-width: $l-desktop) {
				top: 30%;
				left: 9%;

				h4 {
					font-size: 22px;
				}
				h1 {
					font-size: 48px;
					letter-spacing: 0.8px;
				}
			}

			.typography-header{
				margin: 0;
				text-align: left;
			}

			h1 {
				margin-bottom: 10px;
				color: $hover-pink;
				line-height: 1;
			}

			span {
				margin: 0;
				display: block;
				color: $grey;
			}

			button {
				white-space: nowrap;
				width: fit-content;
				margin-top: 50px;
				font-weight: 700;

				@media only screen and (max-width: $s-tablet) {
					margin-top: 10px;
					padding: 6px 10px;
				}
			}
		}
	}
}
