@import 'styles/variables.scss';

.content-wrapper {
	position: relative;
	margin: auto;
	padding: 30px;
	width: 100%;
	max-width: 1400px;

	.content-pics {
		display: flex;
		justify-content: center;
		align-items: center;

		&:hover {
			transform: rotate(-3deg);
		}

		.img-container {
			width: fit-content;
			height: fit-content;
			border-radius: 5px;
		}
	}

	.content-words {
		padding: 30px;
		text-align: center;
		width: 100%;

		@media screen and (min-width: $tablet) {
			text-align: left;
			width: 450px;
		}

		@media screen and (min-width: $desktop) {
			width: 600px;
		}

		h2 {
			margin-bottom: 0;
		}

		p {
			margin: 20px 0px;
		}

		.content-button {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;

			> a {
				margin-bottom: 1rem;
			}
		}

		&:hover ~ .content-pics {
			transform: rotate(-4deg);
		}
	}

	.sticker {
		position: absolute;
		width: 110px;
		height: auto;
		z-index: -1;
	}
}

@media only screen and (min-width: $tablet) {
	.content-wrapper {
		display: flex;
		justify-content: space-around;
		padding: 30px;

		.sticker {
			z-index: 2;
		}
	}
}

@media only screen and (min-width: $desktop) {
	.content-wrapper {
		padding: 40px;
	}
}
