@import 'styles/variables.scss';

.contact-us-page {
	padding: 2rem 2rem;
	position: relative;
	overflow: hidden;
	text-align: center;

	@media screen and (min-width: $tablet) {
		padding: 4rem 6rem;
	}

	@media screen and (min-width: $desktop) {
		padding: 4rem 12rem;
	}

	img {
		width: 6rem;
		height: auto;
	}

	p {
		margin: 2rem 0;
		text-align: center;
	}

	.background-image {
		position: absolute;
		z-index: -999;
		overflow: hidden;

		&.background-image-left {
			width: 13rem;
			left: -5rem;
			top: 12rem;

			@media screen and (min-width: $tablet) {
				width: 18rem;
				left: -6rem;
				top: 18rem;
			}

			@media screen and (min-width: $desktop) {
				width: 18rem;
				top: 22rem;
			}
		}

		&.background-image-right {
			width: 8rem;
			right: -3rem;
			top: 5rem;

			@media screen and (min-width: $desktop) {
				width: 8rem;
				right: -3rem;
				top: 5rem;
			}

			@media screen and (min-width: $desktop) {
				width: 10rem;
			}
		}
	}
}
