@import 'styles/variables.scss';

.kittens-container {
	position: relative;
	padding: 50px 0;
	margin: 20px auto;

	@media only screen and (min-width: $tablet) {
		margin: 80px auto;
	}
	@media only screen and (min-width: $desktop) {
		margin: 100px auto;
	}

	&:hover {
		.kittens-title img {
			transform: rotate(7deg);
		}
	}

	.kittens-background-img {
		position: absolute;
		width: 100vw;
		z-index: -1;

		@media screen and (max-width: $s-tablet) {
			height: 620px;
			top: 0px;
		}

		@media screen and (min-width: 691px) {
			height: 500px;
		}

		@media screen and (min-width: $desktop) {
			height: 600px;
		}
	}

	.kittens-title {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		margin: 30px 0;
		padding-top: 30px;

		@media screen and (max-width: $tablet) {
			padding-top: 0px;
		}

		h3 {
			margin: 0 30px;
			white-space: nowrap;

			@media screen and (max-width: $s-tablet) {
				margin: 0;
			}
		}
		img {
			width: 60px;
			height: auto;
		}
	}

	h4 {
		text-align: center;
		margin-top: 30px;
		cursor: pointer;
	}
}
