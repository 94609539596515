.cat-animation-wrapper {
    width: 100%;
    height: 7rem;

    .cat-animation {
        width: 10rem;
        position: relative;
        display: inline-block;
        
        .toy {
            position: absolute;
            top: 0;
            left: 0;
            width: 2.7rem;
            animation-name: toy;
            animation-duration: 1500ms;
            animation-iteration-count: infinite;
        }
        
        .cat {
            width: 6.5rem;
            position: absolute;
            right: 0;
        }
    }
}

@keyframes toy {
    0%   {transform: rotate(0)}
    50%  {transform: rotate(30deg)}
    100% {transform: rotate(0)}
}