@import 'styles/variables.scss';

.kings-page {
	margin-bottom: 3rem;

	@media screen and (min-width: $tablet) {
		margin-bottom: 5rem;
	}

	.kings-content {
		display: flex;
		flex-direction: column;

		@media screen and (min-width: $tablet) {
			padding: 2rem;
			margin-bottom: 5rem;
			display: grid;
		}
		@media screen and (min-width: $desktop) {
			padding: 4rem;
			grid-template-columns: repeat(2, 1fr);
		}
	}
	.full-width {
		@media screen and (min-width: $tablet) {
			display: flex;
			max-width: 1240px;
			margin: auto;
		}
	}
}
