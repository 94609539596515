@import 'styles/variables.scss';

.typography-header {
	margin: 2rem auto;
	text-align: center;

	h1 {
		font-weight: 700;
		color: $fujipaws-pink;
		font-size: 30px;
	}
}

.typography-heading2 {
	font-size: 22px;
	margin: 8px auto 0 auto;
	font-weight: 600;
}
.typography-heading3 {
	font-size: 16px;
	font-weight: 700;
	margin: 8px auto 0 auto;
}

.typography-heading4 {
	font-size: 14px;
	font-weight: 700;
}

.typography-paragraph {
	font-size: 14px;
	margin-bottom: 0;
}
.typography-placeholder {
	font-size: 12px;
	margin-bottom: 0;
}

@media only screen and (min-width: $tablet) {
	.typography-header {
		h1 {
			font-size: 34px;
		}
	}

	.typography-heading2 {
		font-size: 28px;
		font-weight: 700;
		margin: 12px auto 0 auto;
	}

	.typography-heading3 {
		font-size: 22px;
		margin: 12px auto 0 auto;
	}

	.typography-heading4 {
		font-size: 16px;
	}

	.typography-paragraph {
		font-size: 16px;
	}
	.typography-placeholder {
		font-size: 14px;
	}
}

@media only screen and (min-width: $desktop) {
	.typography-header {
		h1 {
			font-size: 40px;
		}
	}

	.typography-heading2 {
		font-size: 32px;
		margin: 16px auto 0 auto;
	}

	.typography-heading3 {
		font-size: 24px;
		margin: 16px auto 0 auto;
	}

	.typography-heading4 {
		font-size: 18px;
	}

	.typography-paragraph {
		font-size: 18px;
	}
	.typography-placeholder {
		font-size: 16px;
	}
}
