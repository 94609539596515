@import 'styles/variables.scss';

.footer {
	width: 100%;

	.footer-content-container {
		max-width: 1500px;
		margin: auto;
		padding: 20px 30px;

		@media only screen and (min-width: $tablet) {
			padding: 24px 40px;
		}
		@media only screen and (min-width: $desktop) {
			padding: 30px 60px;
		}

		.footer-header {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			margin: 0 auto 20px auto;

			@media only screen and (min-width: $tablet) {
				align-items: baseline;
				justify-content: flex-start;
				margin: 0 auto 30px auto;
			}

			img {
				margin-right: 20px;
				width: 36px;

				@media only screen and (min-width: $tablet) {
					margin-right: 30px;
					width: 40px;
				}
				@media only screen and (min-width: $desktop) {
					margin-right: 40px;
					width: 48px;
				}
			}

			h2 {
				margin: 0;
				color: $grey;
			}
		}

		.footer-content {
			width: 100%;
			flex-wrap: wrap;

			@media only screen and (min-width: $tablet) {
				display: flex;
				justify-content: space-between;
				flex-wrap: nowrap;
			}

			.contact-form.ant-form {
				margin: 0;
			}

			> div {
				width: 50%;

				@media only screen and (max-width: $tablet) {
					width: 100%;
				}
			}
		}
	}

	.footer-base {
		display: flex;
		justify-content: space-between;
		background-color: #efd2c7;
		margin-top: 34px;
		padding: 14px 30px;
		align-items: center;
		flex-wrap: wrap;

		.social-media-container {
			display: flex;
		}

		p {
			color: $text-black;
		}

		img {
			padding-right: 12px;
			width: 28px;
			cursor: pointer;
		}

		@media only screen and (min-width: $tablet) {
			margin-top: 70px;
			padding: 24px 50px;

			img {
				width: 38px;
			}
		}

		@media only screen and (min-width: $desktop) {
			margin-top: 80px;
			padding: 30px 60px;

			img {
				width: 40px;
			}
		}
	}
}
