@import 'styles/variables.scss';

.cat-preview-wrapper {
	width: 100%;
	margin: 0 auto 80px auto;
	text-align: center;

	.cat-img {
		transition: transform ease-in-out 400ms;
		cursor: pointer;

		&:hover {
			transform: scale(1.01);
			opacity: 0.8;
		}
	}
	@media screen and (min-width: $tablet) {
		width: 100%;
	}

	h3 {
		text-transform: uppercase;
	}
}
