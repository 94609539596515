@import 'styles/variables.scss';

.faq-list {
	max-width: 800px;
	margin: auto;
	padding: 30px;

	@media screen and (min-width: $tablet) {
		padding: 30px 60px;
	}

	.list {
		margin: 0;
		padding-top: 50px;

		h3 {
			color: $grey;
			margin: 0;
		}
	}
}
