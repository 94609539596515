.contact-form.ant-form {
	text-align: left;
	max-width: 800px;
	margin: 0 auto;

	label {
		margin-top: 1rem;

		textarea,
		input {
			width: 100%;
			margin-top: 0.5rem;
		}
	}

	.form-text-area {
		resize: none;
		height: 12em;
	}

	.button-wrapper {
		text-align: center;
	}
}